import FiveGridLine_ShadowTopLineComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/ShadowTopLine/ShadowTopLine.skin';


const FiveGridLine_ShadowTopLine = {
  component: FiveGridLine_ShadowTopLineComponent
};


export const components = {
  ['FiveGridLine_ShadowTopLine']: FiveGridLine_ShadowTopLine
};

